import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import NotFoundImage from "../images/404.png";

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found"/>
		<ContentContainer half>
			<div className="text-center">
				<img src={NotFoundImage} alt="404 not found" className="mw-100"/>
				<h1 className="font-weight-bold mt-5">Scheint als seist du verloren gegangen?</h1>
				<p className="mt-3">Sieht aus als sei hier nichts zu finden. Versuch es doch mal mit einem der anderen Links auf der Seite?</p>
			</div>
		</ContentContainer>
	</Layout>
);

export default NotFoundPage;
